import React, { useEffect, useState } from "react"
import Layout from "../../components/Community/Layout"
import { client, urlFor } from "../../sanity"
import { Flex, Title, Text, Box, Label, Image } from "../../components/system"
import Loading from "../../components/Loading"

const Person = props => {
  const [profile, setProfile] = useState(null)
  useEffect(() => {
    const query =
      '*[_type == "user" && _id==$id]{name, email, bio, avatar{asset->{url}}, link, location}'
    const params = { id: props.location.state.id }

    client.fetch(query, params).then(data => {
      setProfile(data[0])
      console.log(data[0])
    })
  }, [props])

  if (!profile) {
    return <Loading />
  }

  return (
    <Layout>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        mt={[3, 6]}
        px={[3, 5]}
        mb={5}
      >
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <Title
            fontWeight="bold"
            fontSize={[6, 7]}
            textAlign="left"
            color="primary"
          >
            {profile.name}
          </Title>
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection={["column", "row"]}
        >
          <Box width={["100%", "50%"]}>
            <Image
              src={urlFor(profile.avatar)
                .width(500)
                .height(500)
                .quality(90)
                .url()}
            />
          </Box>

          <Flex flexDirection="column" pl={[0, 5]}>
            <Flex flexDirection="column" alignItems="flex-start" mb={4}>
              <Label color="secondary">Bio</Label>
              <Text textAlign="left" pr={[0, 6]}>
                {profile.bio
                  ? profile.bio
                  : "This user hasn't added their bio yet"}
              </Text>
            </Flex>
            <Flex justifyContent="flex-start" flexWrap="wrap">
              {profile.location && (
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  mb={3}
                  width={["100%", "30%"]}
                >
                  <Label color="secondary">Location</Label>
                  <Text textAlign="left" pr={[0, 6]}>
                    {profile.location}
                  </Text>
                </Flex>
              )}
              {profile.link && (
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  mb={3}
                  width={["100%", "30%"]}
                >
                  <Label color="secondary">Link</Label>
                  <a href={profile.link} target="_blank" rel="noreferrer">
                    <Text textAlign="left" pr={[0, 6]}>
                      {profile.link}
                    </Text>
                  </a>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}
export default Person
