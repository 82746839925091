import React from "react"
import { Flex, Box } from "../system"

const Loading = () => (
  <Flex height="75vh">
    <Box className="loader" width={50} height={50}></Box>
  </Flex>
)

export default Loading
