export default {
  space: [0, 4, 8, 16, 32, 64, 100, 256],
  colors: {
    primary: "#5C4030",
    secondary: "#ED6E49",
    black: "#1C1D21",
    white: "#ffffff",
    faded: "#CECECE",
    shadow: "#797a90",
  },
}
