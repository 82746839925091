import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import theme from "../theme"

import "../layout.css"

const Layout = ({ children, dark }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
